import { VideoBanner } from "@ecologi/react-components/src/VideoBanner";

export default function VideoBannerWidget(props: {
  data: {
    asset_url: string;
    title: string;
    text: string;
  };
}) {
  return (
    <VideoBanner
      assetSrc={props.data.asset_url}
      title={props.data.title}
      text={props.data.text}
    />
  );
}
