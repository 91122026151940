import React from "react";
import styled from "@emotion/styled";
import Hls from "hls.js";
import Plyr from "plyr";
import {
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  forwardRef,
} from "react";

const noop = () => {};

export type VideoPlayerHandle = {
  enterFullscreen: () => void;
};

export type VideoPlayerProps = {
  assetSrc: string; // .m3u8 from MUX HLS format
  onExitFullscreen?: () => void;
  autoplay?: boolean; // Media Engagement Index dependent
};

const VideoPlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;

  // will not be 100% until play clicked otherwise
  .plyr--video {
    flex: 1;
    max-width: 100%;
  }
`;

const Video = styled.video`
  width: 100%;
  object-fit: contain;

  /* Prevent overflow */
  max-width: 100%;
  /* Prevent gap underneath */
  vertical-align: middle;
`;

export const VideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
  (props, ref) => {
    const videoPlayerRef = useRef<HTMLVideoElement>(null);
    const playerInstance = useRef<Plyr | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        enterFullscreen() {
          playerInstance.current?.fullscreen.enter();
        },
      }),
      [playerInstance.current]
    );

    useLayoutEffect(() => {
      const video = videoPlayerRef.current as HTMLVideoElement;

      playerInstance.current = new Plyr(video, {
        autoplay: false,
        invertTime: false,
        resetOnEnd: true,
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "fullscreen",
        ],
      });

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(props.assetSrc);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          if (props.autoplay) video.play();
        });

        window["hls"] = hls;
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // fallback for safari iOS
        video.src = props.assetSrc;
        video.addEventListener("loadedmetadata", function () {
          if (props.autoplay) video.play();
        });
      }

      // Wire up video events to callback
      playerInstance.current.on(
        "exitfullscreen",
        props.onExitFullscreen || noop
      );

      return () => playerInstance.current?.destroy();
    }, []);

    return (
      <VideoPlayerWrapper>
        <Video
          width="100%"
          ref={videoPlayerRef}
          autoPlay={props.autoplay}
          preload="metadata"
          playsInline
          crossOrigin="anonymous"
        ></Video>
      </VideoPlayerWrapper>
    );
  }
);
