import React from "react";
import styled from "@emotion/styled";
import { VideoPlayer } from "../VideoPlayer";
import { Wrapper } from "../Wrapper";

const Container = styled.div`
  overflow: hidden;
  position: relative;
  background: ${(props) => props.theme.colors.charcoal};
`;

// Custom breakpoint due to size of video
const desktopBreakpoint = "1130px";

const ContentContainer = styled.div`
  color: ${(props) => props.theme.colors.snowdrop};
  margin: 50px 20px;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.bp.mobileUp} {
    padding: 60px 60px;
  }

  @media only screen and (min-width: 860px) {
    padding: 60px 40px;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    display: block;
    margin: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex-grow: 1;
  position: relative;
  z-index: 10;

  @media only screen and (min-width: 860px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ContainerInner = styled(Wrapper)`
  display: flex;
  align-items: center;
  padding: 0;
  max-width: initial;

  @media only screen and (min-width: 860px) {
    padding: 20px;
    max-width: 1180px;
  }

  @media only screen and (min-width: ${desktopBreakpoint}) {
    min-height: 550px;
  }
`;

const Title = styled.h2`
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-bottom: 10px;
  text-align: center;

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 32px;
    text-align: left;
  }
`;

const Text = styled.p`
  font: normal normal normal 16px/20px Sofia Pro;
  letter-spacing: 0px;

  font-weight: 300;
  line-height: 1.4;
  opacity: 0.8;
  font-size: 18px;
  text-align: center;

  @media ${(props) => props.theme.bp.tabletUp} {
    text-align: left;
  }
`;

const VideoPlayerContainer = styled.div`
  display: flex;
  align-items: stretch;

  margin: 20px;

  @media only screen and (min-width: 860px) {
    margin: 0px;
  }
`;

type Props = {
  title: string;
  text: string;
  assetSrc: string;
};

export function VideoBanner(props: Props) {
  return (
    <Container>
      <ContainerInner isExtraLarge>
        <Grid>
          <ContentContainer>
            <Title>{props.title}</Title>
            <Text>{props.text}</Text>
          </ContentContainer>
          <VideoPlayerContainer>
            <VideoPlayer assetSrc={props.assetSrc} autoplay={false} />
          </VideoPlayerContainer>
        </Grid>
      </ContainerInner>
    </Container>
  );
}
